import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>
        Cloudflare static website :: Hello
      </h1>
      <br/>
      <h2>Testing CI/CD</h2>
    </div>
  );
}

export default App;
